var _this = this;

import * as tslib_1 from "tslib";
export var OtherErrors;

(function (OtherErrors) {
  OtherErrors[OtherErrors["FetchError"] = 0] = "FetchError";
})(OtherErrors || (OtherErrors = {}));

export var HttpErrors;

(function (HttpErrors) {
  HttpErrors[HttpErrors["ForbiddenRequestError"] = 0] = "ForbiddenRequestError";
  HttpErrors[HttpErrors["NotFoundRequestError"] = 1] = "NotFoundRequestError";
  HttpErrors[HttpErrors["UnknownRequestError"] = 2] = "UnknownRequestError";
  HttpErrors[HttpErrors["UnauthenticatedRequestError"] = 3] = "UnauthenticatedRequestError";
  HttpErrors[HttpErrors["InvalidRequestError"] = 4] = "InvalidRequestError";
})(HttpErrors || (HttpErrors = {})); //
// Globals
//


var responseInterceptors = [];
export var registerResponseInterceptor = function registerResponseInterceptor(interceptor) {
  responseInterceptors.push(interceptor);
};
export var unregisterResponseInterceptor = function unregisterResponseInterceptor(interceptor) {
  var index = responseInterceptors.indexOf(interceptor);

  if (index >= 0) {
    responseInterceptors.splice(index, 1);
  }
};
export var json = function json(body) {
  return new Blob([JSON.stringify(body)], {
    type: 'application/json'
  });
};
export var hyperfetch = function hyperfetch(url, options) {
  if (options === void 0) {
    options = {};
  } // Cancel logic


  var controller = new AbortController(); // do fetch

  var responsePromise = fetch(url, tslib_1.__assign({
    credentials: 'same-origin'
  }, options, {
    headers: new Headers(options.headers || {}),
    signal: controller.signal
  })); // Register response interceptors

  responseInterceptors.forEach(function (interceptor) {
    responsePromise = responsePromise.then(function (res) {
      return interceptor(res, options);
    });
  }); // handle result

  var returnPromise = responsePromise.then(function (response) {
    return tslib_1.__awaiter(_this, void 0, void 0, function () {
      var _a, _b;

      return tslib_1.__generator(this, function (_c) {
        switch (_c.label) {
          case 0:
            if (!(response.status < 300)) return [3
            /*break*/
            , 4];
            _a = {};
            if (!(response.status === 200)) return [3
            /*break*/
            , 2];
            return [4
            /*yield*/
            , response.json()];

          case 1:
            _b = _c.sent();
            return [3
            /*break*/
            , 3];

          case 2:
            _b = undefined;
            _c.label = 3;

          case 3:
            return [2
            /*return*/
            , (_a.data = _b, _a)];

          case 4:
            switch (response.status) {
              case 400:
                return [2
                /*return*/
                , {
                  error: HttpErrors.InvalidRequestError,
                  errorData: response.json()
                }];

              case 401:
                return [2
                /*return*/
                , {
                  error: HttpErrors.UnauthenticatedRequestError
                }];

              case 403:
                return [2
                /*return*/
                , {
                  error: HttpErrors.ForbiddenRequestError
                }];

              case 404:
                return [2
                /*return*/
                , {
                  error: HttpErrors.NotFoundRequestError
                }];

              default:
                return [2
                /*return*/
                , {
                  error: HttpErrors.UnknownRequestError
                }];
            }

            _c.label = 5;

          case 5:
            return [2
            /*return*/
            ];
        }
      });
    });
  }).catch(function (e) {
    if (e instanceof DOMException) {
      throw e;
    }

    return {
      error: OtherErrors.FetchError,
      errorData: e
    };
  });
  return {
    promise: returnPromise,
    abort: function abort() {
      controller.abort();
    }
  };
};
export var failOnNullOrError = function failOnNullOrError(_a) {
  var promise = _a.promise;
  return tslib_1.__awaiter(_this, void 0, void 0, function () {
    var _b, data, error;

    return tslib_1.__generator(this, function (_c) {
      switch (_c.label) {
        case 0:
          return [4
          /*yield*/
          , promise];

        case 1:
          _b = _c.sent(), data = _b.data, error = _b.error;

          if (data != null && error == null) {
            return [2
            /*return*/
            , data];
          } else {
            throw new Error('Failed request');
          }

          return [2
          /*return*/
          ];
      }
    });
  });
};