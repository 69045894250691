import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { Icon, message, Upload } from 'antd';
import { BrowserBarcodeReader } from '@zxing/library';
import { BarcodeContext, useBarcode } from '../../context/barcode/BarcodeProvider';

const ScUpload = styled(Upload)`
  text-align: center;
`;

const MAX_FILE_MB: number = 2;

export const FileScanner = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [imageUrl, setImageUrl] = useState<string | null>(null);

  const { t } = useTranslation();
  const { setText, confirm }: BarcodeContext = useBarcode();

  const beforeImageUpload = file => {
    const isValidType: boolean = file.type === 'image/jpeg' || file.type === 'image/png';
    const isValidSize: boolean = file.size / 1024 / 1024 < MAX_FILE_MB;

    if (!isValidType || !isValidSize) {
      message.error(t('FILE_SCANNER_UPLOAD_ERROR_MESSAGE'));
    }

    return isValidType && isValidSize;
  };

  const imageUploadRequest = req => {
    setTimeout(() => {
      req.onSuccess('ok');
    }, 0);
  };

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const handleImageChange = info => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }

    if (info.file.status === 'done') {
      getBase64(info.file.originFileObj, imageUrl => {
        setLoading(false);
        setImageUrl(imageUrl);
      });
    }
  };

  useEffect(() => {
    const codeReader = new BrowserBarcodeReader();
    if (imageUrl) {
      codeReader
        .decodeFromImageUrl(imageUrl)
        .then(result => {
          setText(result['text']);
          confirm(result['text']);
        })
        .catch(err => {
          message.error(t('BARCODE_DECODING_FAIL_MESSAGE'));
        });
    }

    return () => {
      codeReader.reset();
    };
  }, [confirm, imageUrl, setText, t]);

  return (
    <>
      <div>{t('CONTENT__FILE_SCANNER_MESSAGE')}</div>
      <ScUpload
        name="avatar"
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={false}
        beforeUpload={beforeImageUpload}
        customRequest={imageUploadRequest}
        onChange={handleImageChange}
      >
        {imageUrl ? (
          <img src={imageUrl} alt="avatar" style={{ width: '100%' }} />
        ) : (
          <div>
            <Icon type={loading ? 'loading' : 'plus'} />
            <div className="ant-upload-text">{t('CONTENT__FILE_SCANNER_UPLOAD_MESSAGE')}</div>
          </div>
        )}
      </ScUpload>
    </>
  );
};
