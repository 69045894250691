import React, { useState } from 'react';
import { Route, Switch } from 'react-router';
import { useBarcode } from '../context/barcode/BarcodeProvider';
import NotFound from './NotFound';
import { Layout, Menu, Icon, Input, Modal, Avatar } from 'antd';
import { CameraScanner } from './scanners/CameraScanner';
import { TextScanner } from './scanners/TextScanner';
import { FileScanner } from './scanners/FileScanner';
import { HardwareScanner } from './scanners/HardwareScanner';
import Info from './pages/Info';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../context/auth/AuthProvider';
import { useXdmsConfig } from '../context/config/XdmsConfigProvider';
import { useUser } from '../context/user/UserProvider';
import { Settings } from './Settings';
import ErrorPage from './ErrorPage';
import { useTenant } from '../context/tenant/TenantProvider';
import { Loading } from '../components/Loading';

const { Header, Content, Sider } = Layout;
const { SubMenu } = Menu;
const { confirm } = Modal;

const ScSider = styled(Sider)`
  background-color: #011524;
`;

const ScHeader = styled(Header)`
  padding-left: 10px !important;
  padding-right: 10px !important;
  background: #166ab1 !important;
`;

const ScInput = styled(Input)`
  width: 100%;
  > input {
    border-radius: 0;
  }
`;

const ScLogo = styled('div')`
  height: 32px;
  margin: 16px;
  text-align: center;
`;

const ScLogoImg = styled('img')`
  height: 100%;
`;

const ScContent = styled(Content)`
  margin: 16px;
`;

const ContentApp = () => {
  const [collapsed, setCollapsed] = useState<boolean>(true);

  const { tenantId } = useTenant();
  const { t } = useTranslation();

  const { logout } = useAuth();
  const { user } = useUser();
  const { loaded, config } = useXdmsConfig();
  const barcodeContext = useBarcode();

  const fullname: string = user ? [user.firstName, user.lastName].filter(Boolean).join(' ') : '?';

  const showConfirm = () => {
    confirm({
      title: 'Are your sure you want to logout?',
      content: 'Press OK to continue or Cancel to go back.',
      onOk() {
        logout();
      },
      onCancel() {}
    });
  };

  const toggle = () => {
    setCollapsed(!collapsed);
  };

  const onChange = e => {
    barcodeContext.setText(e.target.value);
  };

  const onMenuClick = () => {
    if (!collapsed) {
      setCollapsed(true);
    }
  };

  return loaded ? (
    config ? (
      <Layout style={{ minHeight: '100vh' }}>
        <ScSider collapsible collapsed={collapsed} onCollapse={toggle}>
          <Link to="/">
            <ScLogo>
              {collapsed ? (
                <ScLogoImg src="/images/scan_logo_small.png" />
              ) : (
                <ScLogoImg src="/images/scan_logo.png" />
              )}
            </ScLogo>
          </Link>
          <div
            style={{ textAlign: 'center', color: '#195EA5', marginBottom: '20px', height: '36px' }}
          >
            {collapsed ? (
              <Avatar style={{ color: '#195EA5', backgroundColor: '#eeeeee' }}>
                {fullname.charAt(0).toUpperCase()}
              </Avatar>
            ) : (
              <div>
                <span>{tenantId.toUpperCase()}</span>
                <br />
                <span>{fullname}</span>
              </div>
            )}
          </div>
          <Menu theme="dark" mode="inline" onClick={onMenuClick}>
            <Menu.Item key="1">
              <Link to="/">
                <Icon type="home" />
                <span>{t('HOME')}</span>
              </Link>
            </Menu.Item>
            <SubMenu
              key="sub2"
              title={
                <span>
                  <Icon type="search" />
                  <span>{t('CAPTURE_MODE')}</span>
                </span>
              }
            >
              <Menu.Item key="2">
                <Link to="/hardware">{t('MENU_SCANNER')}</Link>
              </Menu.Item>
              <Menu.Item key="3">
                <Link to="/text">{t('MENU_TEXT')}</Link>
              </Menu.Item>
              <Menu.Item key="4">
                <Link to="/file">{t('MENU_FILE_UPLOAD')}</Link>
              </Menu.Item>
              <Menu.Item key="5">
                <Link to="/camera">{t('MENU_CAMERA')}</Link>
              </Menu.Item>
            </SubMenu>
            <Menu.Item key="6">
              <Link to="/info">
                <Icon type="info-circle" />
                <span>{t('INFO')}</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="7">
              <Link to="/settings">
                <Icon type="setting" />
                <span>{t('SETTINGS')}</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="8" onClick={showConfirm}>
              <Icon type="logout" />
              <span>{t('LOGOUT')}</span>
            </Menu.Item>
          </Menu>
        </ScSider>

        <Layout style={{ backgroundColor: '#fafbfc' }}>
          <ScHeader>
            <ScInput
              id="barcode"
              placeholder={t('BARCODE_INPUT_PLACEHOLDER')}
              size="large"
              allowClear
              autoFocus
              onChange={onChange}
              value={barcodeContext.text}
              maxLength={60}
              disabled={!barcodeContext.editable}
              ref={barcodeContext.barcodeInputRef}
            />
            <audio className="audio-element">
              <source src="/sounds/scanner-beep.mp3"></source>
            </audio>
          </ScHeader>
          <ScContent>
            <Switch>
              <Route exact path="/" component={HardwareScanner} />
              <Route exact path="/hardware" component={HardwareScanner} />
              <Route exact path="/text" component={TextScanner} />
              <Route exact path="/file" component={FileScanner} />
              <Route exact path="/camera" component={CameraScanner} />
              <Route exact path="/info" component={Info} />
              <Route exact path="/settings" component={Settings} />
              <Route path="*" component={NotFound} />
            </Switch>
          </ScContent>
        </Layout>
      </Layout>
    ) : (
      <ErrorPage title={t('MISSING_XDMS_CONFIG_TITLE')} imageUrl="/images/notSupported.png">
        <>{t('MISSING_XDMS_CONFIG_MESSAGE')} </>
      </ErrorPage>
    )
  ) : (
    <Loading />
  );
};

export default ContentApp;
