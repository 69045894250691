import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { Button } from 'antd';
import { BarcodeContext, useBarcode } from '../../context/barcode/BarcodeProvider';
import { useCallback } from 'react';

const ScButton = styled(Button)`
  margin-top: 20px;
`;

export const TextScanner = () => {
  const { t } = useTranslation();
  const { setEditable, focusInput, text, confirm, loading }: BarcodeContext = useBarcode();

  useEffect(() => {
    setEditable(true);
    focusInput();
    return () => {
      setEditable(false);
    };
  }, [focusInput, setEditable]);

  const onSearchClick = useCallback(() => {
    confirm(text);
  }, [confirm, text]);

  return (
    <>
      <div>{t('CONTENT__TEXT_SCANNER_MESSAGE')}</div>
      <ScButton type="primary" icon="search" size="large" onClick={onSearchClick} loading={loading}>
        {t('CONTENT__TEXT_SCANNER_BUTTON')}
      </ScButton>
    </>
  );
};
