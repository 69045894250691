import React, { ChangeEvent } from 'react';
import { OptionT } from '@hypercharge/scandelivery-commons/lib/types/common';
import { useBarcode, XdmsMode } from '../context/barcode/BarcodeProvider';
import { Dropdown } from '../components/Dropdown';

export const MODE_OPTIONS: OptionT[] = Object.entries(XdmsMode).map(([key, value]) => ({
  label: key,
  value
}));

const LanguageSelector = () => {
  const barcodeContext = useBarcode();

  const handleModeSelection = (e: ChangeEvent<HTMLSelectElement>) => {
    barcodeContext.setMode(e.target.value);
    barcodeContext.setText('');
  };

  return (
    <Dropdown value={barcodeContext.mode} options={MODE_OPTIONS} onChange={handleModeSelection} />
  );
};

export default LanguageSelector;
