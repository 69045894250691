import React from 'react';
import { Redirect, RouteComponentProps } from 'react-router';
import ErrorBoundary from '../components/ErrorBoundary';
import { AuthenticatedAppProviders } from '../context';
import { useAuth } from '../context/auth/AuthProvider';
import { BarcodeProvider } from '../context/barcode/BarcodeProvider';
import ContentApp from './ContentApp';

const AuthenticatedApp = ({ location }: RouteComponentProps<any>) => {
  const { isAuthenticated } = useAuth();

  return isAuthenticated ? (
    <AuthenticatedAppProviders>
      <BarcodeProvider>
        <ErrorBoundary>
          <ContentApp />
        </ErrorBoundary>
      </BarcodeProvider>
    </AuthenticatedAppProviders>
  ) : (
    <Redirect
      to={{
        pathname: '/login',
        state: { from: location }
      }}
    />
  );
};

export default AuthenticatedApp;
