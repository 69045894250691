import { OptionT } from '@hypercharge/scandelivery-commons/lib/types/common';
import React, { ChangeEvent } from 'react';
import styled from 'styled-components/macro';

// This dropdown exists because the Dropdown from Antd does not use the native select

type Props = {
  className?: string;
  value: string;
  options: OptionT[];
  onChange: (e: ChangeEvent<HTMLSelectElement>) => void;
};

// the styling below is meant to make the select look like a
// antd large button
const ScContainer = styled.div`
  background: #fff;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  display: inline-block;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  :hover {
    color: #259de8;
    border-color: #259de8;
  }
`;
const ScSelect = styled.select`
  appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 0;
  cursor: pointer;
  line-height: 40px;
  margin: 0;
  padding: 0 1rem;
`;

export const Dropdown = React.memo(({ className, value, options, onChange }: Props) => {
  return (
    <ScContainer className={className}>
      <ScSelect value={value} onChange={onChange}>
        {options.map(op => (
          <option key={op.value} value={op.value}>
            {op.label}
          </option>
        ))}
      </ScSelect>
    </ScContainer>
  );
});
