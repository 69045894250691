import { Layout } from 'antd';
import { toUpper } from 'lodash';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router';
import styled from 'styled-components/macro';
import { APP_NAME } from '@hypercharge/scandelivery-commons/lib/constants';
import ErrorBoundary from '../components/ErrorBoundary';
import { UnauthenticatedAppProviders } from '../context';
import { useAuth } from '../context/auth/AuthProvider';
import { useTenant } from '../context/tenant/TenantProvider';
import EmailCodeForm from '../login/EmailCodeForm';
import EmailLoginForm from '../login/EmailLoginForm';
import AppFooter from './AppFooter';

const { Content } = Layout;

const ScLayout = styled(Layout)`
  min-height: 100% !important;
`;

export const ScMessage = styled.div`
  margin: auto;
  margin-top: 20px;
  text-align: center;
  font-size: 0.8rem;
  max-width: 280px;
`;

const UnauthenticatedApp = ({ location }: RouteComponentProps<any>) => {
  const { isAuthenticated } = useAuth();
  const { t } = useTranslation();
  const { tenantId } = useTenant();
  const { from } = location.state || { from: { pathname: '/' } };

  return !isAuthenticated ? (
    <UnauthenticatedAppProviders>
      <ScLayout>
        <Helmet title={`${toUpper(tenantId)} ${APP_NAME}: ${t('AUTH__SIGN_IN')}`} />
        <Content>
          <ErrorBoundary>
            <Switch>
              <Route path="/login/code" component={EmailCodeForm} />
              <Route path="/login" component={EmailLoginForm} />
              <Redirect to="/login" />
            </Switch>
            <ScMessage>
              <a href="mailto:hyper@xpower.be" target="_blank" rel="noopener noreferrer">
                {t('AUTH__SUPPORT_MESSAGE')}
              </a>
            </ScMessage>
          </ErrorBoundary>
        </Content>
        <AppFooter />
      </ScLayout>
    </UnauthenticatedAppProviders>
  ) : (
    <Redirect to={from} />
  );
};

export default UnauthenticatedApp;
