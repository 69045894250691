import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import uuid from '../utils/uuid';

const CsCheckBox = styled.div`
  display: inline-block;
  width: 80px;
  height: 40px;
  position: relative;
  & input {
    display: none;
  }
  & label {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    border: solid 1px #d9d9d9;
    border-radius: 20px;
    background-color: #fff;
  }
  & label:after {
    left: 0;
    content: '';
    display: block;
    position: absolute;
    border: solid 1px #166ab1;
    background-color: #d9d9d9;
    height: 100%;
    width: 50%;
    border-radius: 20px;
    transition: left 0.3s;
  }
  & input:checked + label:after {
    left: 50%;
    background-color: #166ab1;
  }
`;

export const Selector = ({ checked, onChange }) => {
  const [id, setId] = useState<string>('xxx-xxx-xxx');

  useEffect(() => {
    setId(uuid());
  }, []);

  return (
    <CsCheckBox>
      <input
        type="checkbox"
        id={id}
        checked={checked}
        onChange={({ target: { checked } }) => onChange(checked)}
      />
      <label htmlFor={id}></label>
    </CsCheckBox>
  );
};
