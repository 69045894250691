import React from 'react';
import styled from 'styled-components';
import LanguageSelector from './LanguageSelector';
import XdmsModeSelector from './XdmsModeSelector';
import SoundSelector from './SoundSelector';
import { useTranslation } from 'react-i18next';

const ScSettingsBlock = styled.div`
  margin-bottom: 20px;
`;

export const Settings = () => {
  const { t } = useTranslation();

  return (
    <>
      <ScSettingsBlock>
        <h3>{t('SETTINGS_XDMS_MODE_TITLE')}</h3>
        <XdmsModeSelector />
      </ScSettingsBlock>
      <ScSettingsBlock>
        <h3>{t('SETTINGS_LANGUAGE_TITLE')}</h3>
        <LanguageSelector />
      </ScSettingsBlock>
      <ScSettingsBlock>
        <h3>{t('SETTINGS_SOUND_NOTIFICATION_TITLE')}</h3>
        <SoundSelector />
      </ScSettingsBlock>
    </>
  );
};
