import {
  AuthData,
  DisplayAccount,
  RefreshTokenResponse
} from '@hypercharge/scandelivery-commons/lib/types/auth';
import {
  FetchResponse,
  FetchValue,
  hyperfetch,
  json
} from '@hypercharge/scandelivery-commons/lib/utils/httpClient';

const AUTH_API_PATH = '/api/auth';

export const login = async (
  tenantId: string,
  userId: string,
  email: string,
  password: string
): Promise<AuthData | undefined> => {
  try {
    const { promise } = hyperfetch<AuthData>(`${AUTH_API_PATH}/email/login`, {
      method: 'POST',
      body: json({
        tenantId,
        email,
        entityId: userId,
        code: password
      })
    });

    const fetchResult: FetchValue<AuthData> = await promise;
    if (fetchResult.data != null) {
      return fetchResult.data;
    }
  } catch (e) {
    console.log('failed auth');
  }

  return;
};

export const refreshToken = async (tenantId: string, authData: AuthData): Promise<AuthData> => {
  const { promise } = hyperfetch<RefreshTokenResponse>(`${AUTH_API_PATH}/refresh`, {
    method: 'POST',
    body: json({
      tenantId,
      entityId: authData.userId
    })
  });

  const fetchResult: FetchValue<RefreshTokenResponse> = await promise;
  if (fetchResult.data != null) {
    return {
      ...authData,
      jwtExpiry: fetchResult.data.jwtExpiry
    };
  } else if (fetchResult.error != null) {
    throw new Error(fetchResult.error.toString());
  } else {
    throw new Error('No content received');
  }
};

export const logout = async (tenantId: string, authData: AuthData): Promise<void> => {
  const { promise } = hyperfetch(`${AUTH_API_PATH}/logout`, {
    method: 'POST',
    body: json({
      tenantId,
      entityId: authData.userId,
      refreshTokenExpiry: authData.refreshTokenExpiry
    })
  });

  await promise;
};

export const requestEmailCode = (tenantId: string, email: string): FetchResponse<void> =>
  hyperfetch(`${AUTH_API_PATH}/email/request`, {
    method: 'POST',
    body: json({
      tenantId,
      email
    })
  });

export const listAccountsByEmail = (
  tenantId: string,
  email: string,
  code: string
): FetchResponse<DisplayAccount[]> =>
  hyperfetch<DisplayAccount[]>(`${AUTH_API_PATH}/email/accounts`, {
    method: 'POST',
    body: json({
      tenantId,
      email,
      code
    })
  });
