import { history } from '@hypercharge/scandelivery-commons/lib/providers/router/history';
import React, { Suspense } from 'react';
import { Router } from 'react-router';
import { Loading } from '../components/Loading';
import { LoginDataProvider } from '../login/LoginDataProvider';
import { AuthProvider } from './auth/AuthProvider';
import './i18n'; // import i18n just so that it gets bundled
import { OnlineProvider } from './online/OnlineProvider';
import { Splash } from './splash/Splash';
import { SplashProvider } from './splash/SplashProvider';
import { TenantProvider } from './tenant/TenantProvider';
import { XdmsConfigProvider } from './config/XdmsConfigProvider';
import { UserProvider } from './user/UserProvider';

export const AppProviders = ({ children }: any) => {
  return (
    <TenantProvider>
      <Router history={history}>
        <Suspense fallback={<Loading />}>
          <SplashProvider>
            <OnlineProvider>
              <AuthProvider>{children}</AuthProvider>
            </OnlineProvider>
          </SplashProvider>
        </Suspense>
      </Router>
    </TenantProvider>
  );
};

export const AuthenticatedAppProviders = ({ children }: any) => {
  return (
    <XdmsConfigProvider>
      <UserProvider>
        <Splash>{children}</Splash>
      </UserProvider>
    </XdmsConfigProvider>
  );
};

export const UnauthenticatedAppProviders = ({ children }: any) => {
  return (
    <LoginDataProvider>
      <Splash>{children}</Splash>
    </LoginDataProvider>
  );
};
