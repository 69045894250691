import { Button } from 'antd';
import styled from 'styled-components/macro';

export const ScContainer = styled.div`
  margin: auto;
  margin-top: 8rem;
  max-width: 320px;
  text-align: center;
`;

export const ScButton = styled(Button)`
  width: 100%;
`;

export const ScTitle = styled.div`
  font-size: 1.4rem;
  margin: 1.5rem 0;
`;

export const ScLogoImg = styled.img`
  max-width: 90vw;
  width: 200px;
`;
