import React from 'react';
import { Selector } from '../components/Selector';
import { useBarcode, SoundMode } from '../context/barcode/BarcodeProvider';

export default () => {
  const barcodeContext = useBarcode();

  const handleModeSelection = (selected: boolean) => {
    barcodeContext.setSoundMode(selected ? SoundMode.ON : SoundMode.OFF);
  };

  return (
    <Selector checked={barcodeContext.soundMode === SoundMode.ON} onChange={handleModeSelection} />
  );
};
