import React, { PropsWithChildren, useContext, useEffect, useState, useMemo } from 'react';
import { isEmpty } from 'lodash';
import {
  failOnNullOrError,
  hyperfetch
} from '@hypercharge/scandelivery-commons/lib/utils/httpClient';
import { ConfigObject } from '@hypercharge/configs/lib/types';

export type XdmsConfigContext = {
  loaded: boolean;
  config: ConfigObject | null;
};

const XdmsConfigContext = React.createContext<XdmsConfigContext | undefined>(undefined);

export const XdmsConfigProvider = (props: PropsWithChildren<any>) => {
  const [loaded, setLoaded] = useState(false);
  const [xdmsConfig, setXdmsConfig] = useState<ConfigObject | null>(null);

  useEffect(() => {
    fetchData()
      .then(response => {
        if (response.data && !isEmpty(response.data.user) && !isEmpty(response.data.pass)) {
          setXdmsConfig(response);
        }
        setLoaded(true);
      })
      .catch(err => {
        console.error(err);
        setLoaded(true);
      });
  }, []);

  const contextObject = useMemo(
    () => ({
      loaded,
      config: xdmsConfig
    }),
    [loaded, xdmsConfig]
  );

  return <XdmsConfigContext.Provider value={contextObject} {...props} />;
};

export const useXdmsConfig = (): XdmsConfigContext => {
  const context = useContext(XdmsConfigContext);
  if (context === undefined) {
    throw new Error('useXdmsConfig must be used within an XdmsConfigProvider');
  }
  return context;
};

const fetchData = async (): Promise<ConfigObject> =>
  failOnNullOrError<ConfigObject>(hyperfetch<ConfigObject>('/api/xdms/config', { method: 'GET' }));
