import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { BarcodeContext, useBarcode } from '../../context/barcode/BarcodeProvider';
import { useCallback } from 'react';
import Spinner from 'react-spinkit';
import styled from 'styled-components';

const ScSpinner = styled(Spinner)``;

export const HardwareScanner = () => {
  const { t } = useTranslation();
  const {
    setEditable,
    focusInput,
    blurInput,
    text,
    confirm,
    loading
  }: BarcodeContext = useBarcode();

  const onKeyDown = useCallback(
    e => {
      setEditable(true);
      focusInput();
    },
    [focusInput, setEditable]
  );

  const onKeyUp = useCallback(
    e => {
      setEditable(false);
      blurInput();
    },
    [blurInput, setEditable]
  );

  useEffect(() => {
    if (text) {
      confirm(text);
    }
  }, [confirm, text]);

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown);
    document.addEventListener('keyup', onKeyUp);

    return () => {
      document.removeEventListener('keydown', onKeyDown);
      document.removeEventListener('keyup', onKeyUp);
    };
  }, [onKeyDown, onKeyUp]);

  return (
    <>
      {!loading ? (
        <div>{t('CONTENT__HARDWARE_SCANNER_MESSAGE')}</div>
      ) : (
        <ScSpinner name="three-bounce" fadeIn="none" />
      )}
    </>
  );
};
