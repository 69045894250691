import { OptionT } from '@hypercharge/scandelivery-commons/lib/types/common';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-xhr-backend';
import { map } from 'lodash';
import { initReactI18next } from 'react-i18next';
import { FALLBACK_LANGUAGE } from '../../utils/constants';
import { load } from '../../utils/storage';

export const storageKey = '__hyper_scandelivery_language__';

export const LANGUAGE_OPTIONS: OptionT[] = [
  { value: 'en', label: 'English' },
  { value: 'fr', label: 'Français' },
  { value: 'nl', label: 'Nederlands' }
];

const options = {
  interpolation: {
    escapeValue: false // not needed for react
  },
  lng: load<string>(storageKey) || FALLBACK_LANGUAGE,
  fallbackLng: FALLBACK_LANGUAGE,
  whitelist: map(LANGUAGE_OPTIONS, 'value'),
  react: {
    useSuspense: true
  }
};

i18n
  .use(Backend) // load translations from /public/locales
  .use(LanguageDetector)
  .use(initReactI18next)
  .init(options);

export default i18n;
