import { message } from 'antd';
import { Field, Formik, FormikActions, FormikProps } from 'formik';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router';
import * as Yup from 'yup';
import { listAccountsByEmail } from '../context/auth/authClient';
import { useAuth } from '../context/auth/AuthProvider';
import { useTenant } from '../context/tenant/TenantProvider';
import { FormInput } from './FormInput';
import { useLoginData } from './LoginDataProvider';
import { ScButton, ScContainer, ScLogoImg, ScTitle } from './styles';

type CodeValues = {
  code: string;
};

const initialValues: CodeValues = {
  code: ''
};

const EmailCodeForm = () => {
  const { t } = useTranslation();
  const { tenantId } = useTenant();
  const { login } = useAuth();
  const { email } = useLoginData();

  const onSubmit = useCallback(
    async (values: CodeValues, actions: FormikActions<CodeValues>) => {
      if (email == null) {
        actions.setSubmitting(false);
        return;
      }

      const { promise } = listAccountsByEmail(tenantId, email, values.code);
      const { data, error } = await promise;
      if (error != null || data == null || data.length === 0) {
        message.warning(t('AUTH__FAILED_TO_LOGIN_USER_NOT_FOUND'));
      } else {
        const isLoggedIn: boolean = await login(data[0].entityId, email, values.code);
        if (!isLoggedIn) {
          message.warning(t('AUTH__FAILED_TO_LOGIN'));
        }
      }
      actions.setSubmitting(false);
    },
    [email, login, tenantId, t]
  );

  const formSchema = useMemo(
    () =>
      Yup.object().shape({
        code: Yup.string()
          .matches(/\d+/, { excludeEmptyString: true, message: t('FORM__NUMERIC') })
          .min(6, t('FORM__MINIMUM', { min: '6' }))
          .max(6, t('FORM__MAXIMUM', { max: '6' }))
          .required(t('FORM__REQUIRED'))
      }),
    [t]
  );

  return email == null ? (
    <Redirect
      to={{
        pathname: '/login'
      }}
    />
  ) : (
    <ScContainer>
      <ScLogoImg src="/images/scan_logo.png" />
      <ScTitle>
        {tenantId.toUpperCase()}
        <br />
        {t('AUTH__SIGN_IN')}
      </ScTitle>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validateOnBlur={true}
        validateOnChange={false}
        validationSchema={formSchema}
      >
        {(formProps: FormikProps<CodeValues>) => {
          return (
            <form noValidate onSubmit={formProps.handleSubmit}>
              <Field
                component={FormInput}
                disabled={formProps.isSubmitting}
                name="code"
                placeholder={t('AUTH__CODE_LABEL')}
                size="large"
                type="text"
                value={formProps.values.code}
                autoFocus
              />
              <ScButton
                loading={formProps.isSubmitting}
                onClick={formProps.handleSubmit as any}
                type="primary"
                size="large"
              >
                {t('AUTH__CONTINUE')}
              </ScButton>
            </form>
          );
        }}
      </Formik>
    </ScContainer>
  );
};

export default EmailCodeForm;
