import React from 'react';
import styled from 'styled-components/macro';
import { Timeline } from 'antd';

const ScBlock = styled('div')`
  text-align: left;
  margin-bottom: 20px;
`;

const ScImage = styled('img')`
  text-align: left;
  margin-bottom: 20px;
  max-width: 80%;
  display: block;
`;

const Info = () => {
  return (
    <>
      <ScBlock>
        <div>
          <ScImage src="/images/xpower-logo.png" alt="XPOWER N.V. logo" />
          Developed by{' '}
          <a href="https://xpower.be" target="_blank" rel="noopener noreferrer">
            Xpower N.V.
          </a>
        </div>
        <div>
          For additional information or support please contact:{' '}
          <a href="mailto:app@xpower.be" target="_blank" rel="noopener noreferrer">
            apps@xpower.be
          </a>
        </div>
      </ScBlock>

      <ScBlock>
        <h3>Release notes</h3>
        <Timeline>
          <Timeline.Item>(2019/12) Initial redesigned application version.</Timeline.Item>
        </Timeline>
      </ScBlock>
    </>
  );
};

export default Info;
