import React from 'react';
import styled from 'styled-components';
import LanguageSelector from './LanguageSelector';

const now = new Date();
const currentYear = now.getFullYear();

const ScContainer = styled.div`
  margin-top: 1rem;
`;

const ScLanguageSelector = styled(LanguageSelector)`
  align-self: center;
`;

const ScCopyrightMessage = styled.span`
  font-size: 0.8rem;
  align-self: center;
`;

const ScContent = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: baseline;
  align-content: center;
  height: 100%;
  border-top: 1px solid #dfdfdf;
  padding: 1rem 20px;
  font-size: 0.8rem;
`;

const AppFooter = () => (
  <ScContainer>
    <ScContent>
      <ScCopyrightMessage>© {currentYear} XPower N.V.</ScCopyrightMessage>
      <ScLanguageSelector />
    </ScContent>
  </ScContainer>
);

export default AppFooter;
