import React, { PropsWithChildren, useEffect, useMemo, useState, useCallback, useRef } from 'react';
import { ConfirmFromScanResponse } from '@hypercharge/xdms/lib/xdmsTypes';
import {
  failOnNullOrError,
  hyperfetch,
  json
} from '@hypercharge/scandelivery-commons/lib/utils/httpClient';
import { useTranslation } from 'react-i18next';
import { Modal, Input } from 'antd';
import { load, save } from '../../utils/storage';

export const storageKey = '__hyper_scandelivery_xdms_mode__';
export const storageSoundModeKey = '__hyper_scandelivery_sound_mode__';
export const MINIMUM_BARCODE_SIZE: number = 3;

export enum XdmsMode {
  PROD = '',
  INFO = 'info',
  TEST = 'test'
}

export enum SoundMode {
  ON = '1',
  OFF = ''
}

export type BarcodeContext = {
  text: string;
  setText: Function;
  mode: string;
  setMode: Function;
  soundMode: string;
  setSoundMode: Function;
  editable: boolean;
  setEditable: Function;
  loading: boolean;
  confirm: Function;
  barcodeInputRef: React.RefObject<Input>;
  focusInput: () => void;
  blurInput: () => void;
};

const BarcodeContext = React.createContext<BarcodeContext | undefined>(undefined);

export const BarcodeProvider = ({ children }: PropsWithChildren<{}>) => {
  const storedXdmsMode: XdmsMode | undefined = load<XdmsMode>(storageKey);
  const storedSoundMode: SoundMode | undefined = load<SoundMode>(storageSoundModeKey);

  const [text, setText] = useState('');
  const [mode, setMode] = useState<XdmsMode>(
    storedXdmsMode != null ? storedXdmsMode : XdmsMode.TEST
  );
  const [soundMode, setSoundMode] = useState<SoundMode>(
    storedSoundMode != null ? storedSoundMode : SoundMode.OFF
  );
  const [editable, setEditable] = useState(false);
  const [loading, setLoading] = useState(false);

  const barcodeInputRef: React.RefObject<Input> = useRef(null);

  const { t } = useTranslation();

  useEffect(() => {
    save(storageKey, mode);
    save(storageSoundModeKey, soundMode);
  }, [mode, soundMode]);

  const onModalOk = () => {
    setText('');
    setLoading(false);
    setEditable(true);
  };

  const focusInput = useCallback(() => {
    if (barcodeInputRef.current) {
      barcodeInputRef.current.focus();
    }
  }, []);

  const blurInput = useCallback(() => {
    if (barcodeInputRef.current) {
      barcodeInputRef.current.blur();
    }
  }, []);

  const confirm = useCallback(
    (barcode: string) => {
      const audioElem: any = document.getElementsByClassName('audio-element')[0];
      if (!loading) {
        if (barcode.length > MINIMUM_BARCODE_SIZE) {
          
          if (soundMode === SoundMode.ON) {
            audioElem.play();
          }
          

          setLoading(true);
          setEditable(false);
          const confirmFromScanResponse: Promise<ConfirmFromScanResponse> = xdmsConfirmFromScan(
            barcode,
            mode
          );

          confirmFromScanResponse
            .then(confirmFromScanResponse => {
              if (confirmFromScanResponse.NRerr > 0) {
                Modal.error({
                  title: t('BARCODE_CONFIRM_ERROR_TITLE'),
                  content: (
                    <>
                      <div>
                        <strong>
                          {barcode} ({mode})
                        </strong>
                      </div>
                      <div>{confirmFromScanResponse.INerr}</div>
                    </>
                  ),
                  onOk: onModalOk
                });
              } else {
                let secondsToGo: number = 5;
                const successModal = Modal.success({
                  title: t('BARCODE_CONFIRM_SUCCESS_TITLE'),
                  content: (
                    <div>
                      <div>
                        <strong>
                          {barcode} ({mode})
                        </strong>
                      </div>
                      <div>
                        {[confirmFromScanResponse.INmsg, confirmFromScanResponse.INconfirm].filter(
                          Boolean
                        )}
                      </div>
                    </div>
                  ),
                  onOk: onModalOk
                });
                const timer = setInterval(() => {
                  secondsToGo -= 1;
                }, 1000);
                setTimeout(() => {
                  clearInterval(timer);
                  successModal.destroy();
                  onModalOk();
                }, secondsToGo * 1000);
              }
            })
            .catch(() => {
              Modal.error({
                title: t('BARCODE_CONFIRM_ERROR_TITLE'),
                content: (
                  <>
                    <div>
                      <strong>
                        {barcode} ({mode})
                      </strong>
                    </div>
                    <div>Failed request</div>
                  </>
                ),
                onOk: onModalOk
              });
            });
        } else {
          Modal.error({
            title: t('BARCODE_CONFIRM_ERROR_TITLE'),
            content: (
              <>
                <div>
                  <strong>
                    {t('TEXT_SCANNER_SEARCH_ERROR_MESSAGE')} ({mode})
                  </strong>
                </div>
              </>
            )
          });
        }
      }
    },
    [t, mode, loading, soundMode]
  );

  const contextObject = useMemo(
    () => ({
      text,
      setText,
      mode,
      setMode,
      soundMode,
      setSoundMode,
      editable,
      setEditable,
      loading,
      confirm,
      barcodeInputRef,
      focusInput,
      blurInput
    }),
    [
      text,
      setText,
      mode,
      setMode,
      soundMode,
      setSoundMode,
      editable,
      setEditable,
      loading,
      confirm,
      barcodeInputRef,
      focusInput,
      blurInput
    ]
  );

  return <BarcodeContext.Provider value={contextObject}>{children}</BarcodeContext.Provider>;
};

export const useBarcode = (): BarcodeContext => {
  const context = React.useContext(BarcodeContext);
  if (context === undefined) {
    throw new Error('useBarcode must be used within an BarcodeProvider');
  }
  return context;
};

const xdmsConfirmFromScan = async (
  text: string,
  mode: XdmsMode
): Promise<ConfirmFromScanResponse> => {
  return failOnNullOrError<ConfirmFromScanResponse>(
    hyperfetch<ConfirmFromScanResponse>('/api/xdms/confirm-from-scan', {
      method: 'POST',
      body: json({
        barcode: text,
        mode
      })
    })
  );
};
