import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { BrowserBarcodeReader } from '@zxing/library';
import { BarcodeContext, useBarcode } from '../../context/barcode/BarcodeProvider';

const ScVideo = styled('video')`
  text-align: center;
  width: 100%;
`;

export const CameraScanner = () => {
  const { t } = useTranslation();
  const { setText, confirm }: BarcodeContext = useBarcode();

  useEffect(() => {
    const codeReader = new BrowserBarcodeReader();
    if (codeReader.isMediaDevicesSuported) {
      let previousCapturedValue = null;

      const onVideoChange = e => {
        if (e != null && previousCapturedValue !== e.text) {
          previousCapturedValue = e.text;
          setText(e.text);
          confirm(e.text);
        }
      };

      codeReader.decodeFromInputVideoDeviceContinuously(null, 'video', onVideoChange);

      return () => {
        console.log('cleaning video');
        codeReader.reset();
      };
    }
  }, [setText, confirm]);

  return (
    <>
      <div>{t('CONTENT__CAMERA_SCANNER_MESSAGE')}</div>
      <ScVideo id="video"></ScVideo>
    </>
  );
};
