import React from 'react';
import Spinner from 'react-spinkit';
import styled from 'styled-components/macro';

const ScWrapper = styled.div`
  width: 100vw;
  height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 50px;

  .sk-spinner {
    color: #162133;
  }
`;

export const Loading = ({ className }: { className?: string }) => {
  return (
    <ScWrapper className={className}>
      <Spinner name="three-bounce" fadeIn="none" />
    </ScWrapper>
  );
};
