export const FALLBACK_LANGUAGE = 'en';

export const BREAKPOINTS = {
  sm: 576,
  md: 768,
  lg: 1024
};

export const media: { [sizeKey: string]: any } = Object.keys(BREAKPOINTS).reduce(
  (acc: Object, label: string) => {
    acc[label] = `@media screen and (min-width: ${BREAKPOINTS[label] / 16}em)`;
    return acc;
  },
  {}
);
